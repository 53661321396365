import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Online per a la Guàrdia Urbana de l'Hospitalet de Llobregat 2024</Title>
                <Text>
                    Arriba el millor curs per preparar l'accés a la Guàrdia Urbana de l'Hospitalet
                    de Llobregat 2024.
                    <br />
                    <br />
                    En l'última convocatòria d'accés a la Guàrdia Urbana de l'Hospitalet de
                    Llobregat pràcticament tots els alumnes van ser nostres per això enguany us
                    portem un curs encara més complet.
                    <br />
                    <br />
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                    <br />
                    <br />
                    Un curs viu i amb actualitzacions setmanals.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general bàsica
                    <br />
                    <br />
                    - Cultura general avançada
                    <br />
                    <br />
                    - Notícies d'actualitat, vídeos i test per posar-te a prova.
                    <br />
                    <br />
                    -Tots els premis 2024, esports 2024, personalitats...
                    <br />
                    <br />
                    - El Municipi en xifres i dades rellevants.
                    <br />
                    <br />
                    -Consistori
                    <br />
                    <br />
                    - 11 exàmens oficials
                    <br />
                    <br />
                    -Tot el temari actualitzat, amb test e idees força de cada tema.
                    <br />
                    <br />
                    I Simulacres on et posarem a prova!!
                    <br />
                    <br />
                    A més a tots els alumnes tindreu accés a un grupde WhatsApp exclusiu amb Kahoots
                    setmanals
                    <br />
                    <br />
                    Per només 79,99 euros.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/qpho2LH11Yo"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
